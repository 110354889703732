document.addEventListener("turbolinks:load", function () {
  const btn = document.querySelectorAll(".search-btn");

  if (btn.length > 0) {
    btn[0].addEventListener("click", (e) => {
      const searchVal = document.querySelectorAll(".search-input")[0].value;
      window.location.href = `/search/${searchVal}`;
    });
  }
});
